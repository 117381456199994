<template>
  <div class="app-business-wrapper">
    <my-nav class="app-message" title=" 完善APP"></my-nav>
    <div class="examination-object mt25">
      <span class="examination-nav">考籍专业</span>
      <div class="examination-item">{{ reportMessage }}</div>
    </div>
    <div v-if="currentType == 1" class="pirce-object mt20">
      <van-form>
        <van-field
          v-model="state.priceName"
          label="价格体系"
          @click="isShowPriceSystem = true"
          right-icon="arrow"
          input-align="right"
          clickable
          required
          readonly
          placeholder="请选择"
        />
        <van-field
          label="调整课程"
          @click="addCourseList"
          input-align="right"
          right-icon="arrow"
          :border="false"
          readonly
          clickable
          required
          placeholder="去调整"
        />
        <div class="project-lists">
          <div class="project-item" v-for="item in getCheckedList" :key="item.goodsNo">
            <p class="porject-title">{{ `${item.goodsName} - ${item.goodsNo}` }}</p>
            <div class="other-message">
              <span>{{ item.applyType === 0 ? "省考" : "统考" }}</span>
              <span class="type"
                >{{
                  item.consumeType === 1
                    ? "购买"
                    : item.consumeType === 2
                    ? "升级"
                    : "续费"
                }}·{{ item.signLevel === 1 ? "智慧学" : "精品学" }}</span
              >
              <span>¥{{ item.money }}</span>
            </div>
          </div>
        </div>
        <div v-if="getCheckedList.length" class="price-total">
          <span>价格合计</span>
          <span>¥{{ totalPrice }}</span>
        </div>
      </van-form>
    </div>
    <div v-else class="pay-order-wrapper mt25">
      <van-form>
        <van-field
          v-model="state.priceName"
          label="价格体系"
          @click="isShowPriceSystem = true"
          right-icon="arrow"
          input-align="right"
          clickable
          readonly
          required
          placeholder="请选择"
        />
        <van-field label="等级" input-align="right" readonly required>
          <template #input>
            <div>
              <my-status-list
                :statusList="levelList2"
                @getStatusList="getlevelType2"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field v-if="currentDetails.signTypeValue==2" label="报考类型" input-align="right" readonly required>
          <template #input>
            <div>
              <my-status-list
                :statusList="registerTypeList2"
                @getStatusList="getRegisterType"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field
          v-if="state.consumeType === 3"
          v-model="renewYearObj.renewYearMess"
          label="续费年份"
          @click="isRenewYear = true"
          right-icon="arrow"
          input-align="right"
          clickable
          readonly
          required
          placeholder="请选择"
        />
        <van-field v-model="state.totalMoney" input-align="right" readonly label="价格" />
        <van-field :border="false" input-align="right" readonly label="消费类型">
          <template #input>
            <div>
              {{
                state.consumeType == 1
                  ? "购买"
                  : state.consumeType == 2
                  ? "升级"
                  : state.consumeType == 3
                  ? "续费"
                  : ""
              }}
            </div>
          </template>
        </van-field>
      </van-form>
    </div>
    <my-nav class="app-message" title=" 其他信息"></my-nav>
    <div class="pirce-object mt20">
      <my-audit-form
        ref="auditForm"
        :currentType="1"
        @hasDisabled="getAuditStatus"
        :maxMoney="currentType === 1 ? totalPrice : state.totalMoney"
        :disabled="
          currentType === 1
            ? totalPrice
              ? false
              : true
            : state.totalMoney
            ? false
            : true
        "
      ></my-audit-form>
    </div>
    <div class="other-actions-sheet" v-if="isShowPriceSystem">
      <van-action-sheet
        :show="isShowPriceSystem"
        title="价格体系"
        :actions="currentPriceSystemLists"
        @select="getPriceSystem"
        @cancel="isShowPriceSystem = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isRenewYear">
      <van-action-sheet
        :show="isRenewYear"
        title="续费年份"
        :actions="renewYearList"
        @select="getRenwYear"
        @cancel="isRenewYear = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isShowChooseCourse">
      <van-action-sheet
        :show="isShowChooseCourse"
        title="课程调整"
        @select="getPriceSystem"
        @cancel="isShowChooseCourse = false"
      >
        <div class="content">
          <div class="notice_info">
            <van-icon
              name="info"
              color="#1678FF"
              class="mr16"
            />请选择下面课程中的考试类型和等级。
          </div>
          <div class="course-item" v-for="item in getAppLists" :key="item.type">
            <p class="course-title">{{ item.goodsName }}</p>
            <div class="course-status">
              <div class="course-status-item">
                <my-status-list
                  :statusList="examTypeList"
                  @getStatusList="getExamType($event, item)"
                  :checkedData="item.applyType"
                ></my-status-list>
              </div>
              <div class="line"></div>
              <div class="course-status-item">
                <my-status-list
                  :statusList="levelList2"
                  :checkedData="item.signLevel"
                  @getStatusList="getLevel($event, item)"
                ></my-status-list>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>
<script>
import myStatusList from "@/components/statusList";
import { payLists, examTypeList, levelList2, renewYearList, registerTypeList2 } from "@/utils/const";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { CellGroup, Cell, Form, Field, ActionSheet, Toast, Icon } from "vant";
import myNav from "@/components/nav";
import myAuditForm from "@/components/discountsAudit/auditForm";
import { getPriceSystemList, checkBuyAppGoods } from "@/api/common";
import { useRoute } from "vue-router";
export default {
  props: {
    currentBusinessDetails: {
      type: Object,
      default: () => {},
    },
    idCard: {
      type: String,
      default: "",
    },
  },
  components: {
    myStatusList,
    myNav,
    myAuditForm,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Form.name]: Form,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon,
  },
  setup(props, context) {
    const route = useRoute();
    const reportMessage = route.query.reportMessage;
    const state = reactive({
      totalMoney: "",
    });
    const currentPriceSystemLists = ref([]);
    let currentType = ref(0);
    let checkParamsGoodsList = reactive({
      signType: -1,
    });
    let getAppList = reactive({
      getAppLists: [],
    });
    const showModel = reactive({
      isShowPriceSystem: false,
      isShowChooseCourse: false,
      isRenewYear: false,
    });
    // 续费年份
    const renewYearObj = reactive({
      renewYearNum: 1,
      renewYearMess: "1年",
    });
    // 存放验证信息
    const currentProfessinalList = reactive({

    });
    let totalGoodsList = ref({});
    const retrunGoodsList = ref([]);
    // 验证通过存放的值
    const auditForm = ref(null);
    const changeParams = reactive({
      currentAuditStatus: false, // 当前优惠列表必填字段
    });
    const currentDetails = computed(() => {
      return props.currentBusinessDetails;
    });
    const totalPrice = computed(() => {
      return getCheckedList.value.reduce((total, item) => {
        return total + item.money;
      }, 0);
    });
    const getCheckedList = computed(() => {
      return getAppList.getAppLists.filter((item) => {
        return item.applyType !== "-1" && item.signLevel !== "-1";
      });
    });
    // 获取续费年份
    const getRenwYear = (val) => {
      renewYearObj.renewYearMess = val.name;
      renewYearObj.renewYearNum = val.type;
      showModel.isRenewYear = false;
    };
    const checkArrHas = (goodsNo) => {
      // 验证是否有这个商品
      let currentIndex;
      currentIndex = getAppList.getAppLists.findIndex((item) => item.goodsNo === goodsNo);
      return currentIndex;
    };
    const getExamType = (item, current) => {
      let indexs = checkArrHas(current.goodsNo);
      let currentObj = getAppList.getAppLists[indexs];
      currentObj.applyType = item.type;
      if (currentObj.signLevel !== "-1") {
        if(currentDetails.value.signTypeValue == 2 && currentType.value == 2 ) { // 自考本科并且是整专业时要验证报考类型是否选择
          if(currentProfessinalList.applyType) {
            checkBuyAppGood(currentObj, indexs, "applyType");
          }
        } else {
           checkBuyAppGood(currentObj, indexs, "applyType");
        }
      }
    };
    const getLevel = (item, current) => {
      let indexs = checkArrHas(current.goodsNo);
      let currentObj = getAppList.getAppLists[indexs];
      currentObj.signLevel = item.type;
      if (currentObj.applyType !== "-1") {
        if(currentDetails.value.signTypeValue == 2 && currentType.value == 2 ) { // 自考本科并且是整专业时要验证报考类型是否选择
          if(currentProfessinalList.applyType) {
            checkBuyAppGood(currentObj, indexs, "signLevel");
          }
        } else {
           checkBuyAppGood(currentObj, indexs, "signLevel");
        }
      }
    };
    // 点击课程调整时，身份证和价格体系不能为空
    const addCourseList = () => {
      if (props.idCard && state.priceId) {
        showModel.isShowChooseCourse = true;
      } else {
        Toast({
          message: "请先填写身份证和价格体系！",
        });
      }
    };
    // 获取价格价格体系
    const getPriceSystemLists = () => {
      getPriceSystemList({
        type: checkParamsGoodsList.signType === 1 ? 2 : 1, // 1是专业 2 是课程
      }).then((res) => {
        if (res && res.body) {
          currentPriceSystemLists.value = res.body.map((item) => {
            return {
              name: item.name,
              type: item.id,
            };
          });
        }
      });
    };
    const checkBuyAppGood = (currentItem, currentIndex, type) => {
      currentItem.signType = checkParamsGoodsList.signType;
      const dataList = {
        idCard: props.idCard,
        orderType: 1,
        priceSystemId: state.priceId, // 价格体系ID
        priceSystemName: state.priceName, // 价格体系名称
        goodsList: [currentItem],
      };
      checkBuyAppGoods(dataList).then((res) => {
        if (res && res.body) {
          if (!res.body.order) {
            if (currentType.value == 1) {
              getAppList.getAppLists[currentIndex][type] = "-1";
            } else {
              retrunGoodsList.value = [];
              state.totalMoney = "";
              state.consumeType = "";
            }
            Toast({
              message: res.body.goodsList[0].reason,
            });
          } else {
            if (currentType.value == 1) {
              // 单门课程
              // res.body.goodsList[0].name = getAppList.getAppLists[currentIndex].goodsName;
              getAppList.getAppLists[currentIndex] = res.body.goodsList[0];
            } else {
              retrunGoodsList.value = res.body.goodsList;
              state.totalMoney = res.body.goodsList[0].money;
              state.consumeType = res.body.goodsList[0].consumeType;
            }
          }
        }
      });
    };
    const getAuditStatus = (flag) => {
      changeParams.currentAuditStatus = flag;
    };
    // 获取初始化appList 选择框中数据
    const getDefaultAppList = () => {
      getAppList.getAppLists = currentDetails.value.appList.map((item) => {
        return {
          goodsNo: item.id,
          goodsName: item.name,
          applyType: "-1",
          signLevel: "-1",
        };
      });
    };
    // 监听验证成功是否有值，控制按钮是否可以点击
    watch(
      getCheckedList,
      (val) => {
        if (val.length && changeParams.currentAuditStatus) {
          context.emit("hasDisabled", true);
        } else {
          context.emit("hasDisabled", false);
        }
      },
      { deep: true }
    );
    watch(retrunGoodsList, (val) => {
      if (val.length && changeParams.currentAuditStatus) {
        context.emit("hasDisabled", true);
      } else {
        context.emit("hasDisabled", false);
      }
    });
    watch(
      changeParams,
      (val) => {
        if (currentType.value === 1) {
          if (getCheckedList.value.length && val.currentAuditStatus) {
            context.emit("hasDisabled", true);
          } else {
            context.emit("hasDisabled", false);
          }
        } else {
          if (retrunGoodsList.value.length && val.currentAuditStatus) {
            context.emit("hasDisabled", true);
          } else {
            context.emit("hasDisabled", false);
          }
        }
      },
      { deep: true }
    );
    watch(
      currentDetails,
      (val) => {
        if (val) {
          checkParamsGoodsList.signType = val.signTypeValue;
          currentType.value = val.signTypeValue == 1 ? 1 : 2; // signTypeVlue === 1 是单门课程
          // getAppList.getAppLists = val.appList.map((item) => {
          //   return {
          //     goodsNo: item.id,
          //     name: item.name,
          //     applyType: '-1',
          //     signLevel: '-1'
          //   };
          // });
          getDefaultAppList();
          getPriceSystemLists();
        }
      },
      { immediate: true }
    );
    // 返回课程参数值
    const currrentSingular = () => {
      const auditFormParams = auditForm.value.returnCurrentParams();
      let currentParams = {
        idCard: props.idCard,
        orderType: 1,
        priceSystemId: state.priceId,
        priceSystemName: state.priceName,
        ...auditFormParams, // 合并优惠表单参数
      };
      if (checkParamsGoodsList.signType == 1) {
        currentParams.goodsList = getCheckedList.value;
      } else {
        if (retrunGoodsList.value[0].consumeType === 3) {
          // 整专业 且返回类型为续费
          retrunGoodsList.value[0].majorGoodsOverdueTime = renewYearObj.renewYearNum;
        }
        currentParams.goodsList = retrunGoodsList.value;
      }
      return currentParams;
    };
    const getlevelType2 = (item) => {
      currentProfessinalList.type = item.type;
    };
    const getRegisterType = (item) => {
      currentProfessinalList.applyType = item.type
    }
    // 当前选择价格体系
    const getPriceSystem = (item) => {
      state.priceName = item.name;
      state.priceId = item.type;
      currentProfessinalList.priceId = item.type;
      currentProfessinalList.priceName = item.type;
      showModel.isShowPriceSystem = false;
      // 初始化弹框值
      getDefaultAppList();
    };
    // 监听当前整专业必选项
    watch(
      currentProfessinalList,
      (val) => {
         totalGoodsList = {
            goodsName: props.currentBusinessDetails.appList[0].name,
            goodsNo: props.currentBusinessDetails.appList[0].id,
            signLevel: currentProfessinalList.type,
            signType: checkParamsGoodsList.signType,
            applyType: currentProfessinalList.applyType == 2 ? 0 : currentProfessinalList.applyType
          };
        if(currentDetails.value.signTypeValue == 2 && currentType.value == 2 ) {// 自考本科并且是整专业时要验证报考类型是否选择
          if (val.priceId && val.type && val.applyType) {
            // 检测当前值能否通过
            checkBuyAppGood(totalGoodsList);
          }
         }  else {
          if (val.priceId && val.type) {
            // 检测当前值能否通过
            checkBuyAppGood(totalGoodsList);
          }
        }
      },
      { deep: true }
    );
    return {
      payLists,
      examTypeList,
      levelList2,
      reportMessage,
      currentPriceSystemLists,
      currentType,
      totalPrice,
      state,
      renewYearObj,
      getCheckedList,
      auditForm,
      addCourseList,
      getRenwYear,
      getAuditStatus,
      renewYearList,
      currentDetails,
      getlevelType2,
      registerTypeList2,
      getPriceSystem,
      getRegisterType,
      getExamType,
      getLevel,
      currrentSingular,
      ...toRefs(getAppList),
      ...toRefs(showModel),
    };
  },
};
</script>
<style lang="less" scoped>
.app-business-wrapper {
  .app-message {
    margin-top: 48px;
  }
  .examination-object {
    background: #fff;
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .examination-nav,
    .examination-item {
      font-size: 28px;
      color: #333;
    }
    .examination-item {
      max-width: 60vw;
    }
  }
  .pirce-object {
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 24px;
    font-size: 28px;
    color: #333;
    .price-total {
      margin-top: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .project-lists {
      .project-item {
        background: #fafafa;
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 16px;
        .porject-title {
          font-size: 28px;
          font-weight: bold;
          color: #333;
          margin-bottom: 12px;
        }
        .other-message {
          font-size: 26px;
          color: #999;
          & > span:first-child {
            padding-right: 20px;
          }
          & > span:last-child {
            padding-left: 20px;
          }
          .type {
            padding: 0px 20px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
          }
        }
      }
    }
  }
  .pay-order {
    margin-top: 48px;
  }
  .pay-order-wrapper {
    padding: 32px;
    background: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    .pay-type {
      padding: 32px 0px 16px 0px;
      // .pay-type-lists {
      // }
    }
    &:deep(.van-cell:last-child::after) {
      display: inherit !important;
    }
  }
  .content {
    min-height: 40vh;
    .course-item {
      padding-bottom: 35px;
      border-bottom: 1px solid #eee;
      margin-bottom: 35px;
      .course-title {
        color: #333;
        font-size: 30px;
      }
      .course-status {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .line {
          height: 32px;
          padding-right: 20px;
          margin-right: 20px;
          border-right: 1px solid #eee;
        }
      }
    }
  }
  .notice_info {
    padding: 12px 20px;
    color: #1678ff;
    background: rgba(22, 120, 255, 0.1);
    position: relative;
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 16px;
  }
}
</style>
