<template>
  <div v-if="!isOrderSuccess" class="conversion-order-wrapper">
    <my-nav title=" 完善学生信息"></my-nav>
    <div class="student-message mt25">
      <van-form>
        <!-- 通过 pattern 进行正则校验 -->
        <van-field
          v-model="baseInfo.name"
          label="客户名称"
          input-align="right"
          required
          placeholder="请输入"
        />
        <van-field
          v-model="baseInfo.idCard"
          label="身份证号"
          :disabled="idCardDisabled"
          input-align="right"
          name="pattern"
          :rules="[{ validator: checkIdCard, message: '请输入正确身份证号码' }]"
          required
          placeholder="请输入"
        />
        <van-field
          v-model="baseInfo.mobile"
          label="手机号"
          name="reg_phone"
          input-align="right"
          required
          :rules="[{ pattern: reg_phone, message: '请输入正确手机号' }]"
          placeholder="请输入"
        />
        <van-field
          v-model="baseInfo.nation"
          label="民族"
          @click="isShowNation = true"
          readonly
          clickable
          input-align="right"
          right-icon="arrow"
          required
          placeholder="请选择"
        />
        <van-field name="sexList" input-align="right" required label="性别" readonly>
          <template #input>
            <div>
              <my-status-list
                :statusList="sexLists"
                @getStatusList="getStatusSex"
                :checkedData="baseInfo.sex"
              ></my-status-list>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="baseInfo.degreeName"
          @click="isShowDegree = true"
          label="学历"
          right-icon="arrow"
          readonly
          clickable
          input-align="right"
          required
          placeholder="请选择"
        />
      </van-form>
    </div>
    <examination-business
      class="examination_wrapper"
      v-if="businessType == 1 && flag"
      :currentBusinessDetails="dataList"
      @hasDisabled="hasDisabled"
      ref="examination"
    ></examination-business>
    <app-business
      ref="appOrder"
      v-else-if="businessType == 2 && flag"
      :currentBusinessDetails="dataList"
      :idCard="baseInfo.idCard"
      @hasDisabled="hasDisabled"
    ></app-business>
    <div class="footer-wrapper">
      <my-footer
        :successSubmitRight="isFailed"
        :isShowLeft="false"
        :loadingRight="loading"
        :rightText="currentButtonName"
        @handleButtonRight="submitRight"
      ></my-footer>
    </div>
    <div class="other-actions-sheet" v-if="isShowDegree">
      <van-action-sheet
        :show="isShowDegree"
        title="学历"
        :actions="educationList"
        @select="getDegree"
        @cancel="isShowDegree = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isShowNation">
      <van-action-sheet
        :show="isShowNation"
        title="民族"
        :actions="nationLists"
        @select="getNation"
        @cancel="isShowNation = false"
      />
    </div>
  </div>
  <my-pay-order v-else :dataList="getPayList" @closePay="closePay"></my-pay-order>
</template>
<script>
import myNav from "@/components/nav";
import myFooter from "@/components/buttonGroup";
import { Form, Field, ActionSheet, Toast } from "vant";
import { sexList, educationList, nationList } from "@/utils/const";
import { computed, reactive, ref, toRefs, watch } from "vue";
import myStatusList from "@/components/statusList";
import examinationBusiness from "./components/examination";
import appBusiness from "./components/appBusiness";
import { useRoute, useRouter } from "vue-router";
import myPayOrder from "@/components/payorder";
import { useStore } from "vuex";
import {
  getCustomerBusinessById,
  getCustomerDetails,
  addUserStudent,
  addUserExamination,
  findCustomerIdCard,
} from "@/api";
import { reg_idCard, reg_phone, checkSnPays } from "@/utils/check";
import { businessOrderGoods, checkStudentExist, freeSn } from "@/api/common";

export default {
  name: "Order",
  components: {
    myNav,
    myPayOrder,
    myFooter,
    myStatusList,
    examinationBusiness,
    appBusiness,
    [Field.name]: Field,
    [Form.name]: Form,
    [ActionSheet.name]: ActionSheet,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const sexLists = ref(sexList);
    const loading = ref(false);
    const isOrderSuccess = ref(false);
    const getCurrentBusinessDetail = reactive({
      dataList: {},
      currentButtonName: "确认开通", // 当前按钮名称
    });
    const idCardDisabled = ref(false); // 身份证是否禁用
    const flag = ref(false);
    const getPayList = reactive({}); // 获取支付订单相关信息
    const examination = ref(null);
    const appOrder = ref(null);
    const nationLists = nationList.map((item, index) => {
      return {
        name: item.value,
        type: index + 1,
      };
    });
    const hasExamination = ref(false); // 考籍必填项是否已填
    const state = reactive({
      baseInfo: {
        sex: "",
      },
    });
    const showModel = reactive({
      isShowDegree: false,
      isShowNation: false,
    });
    const isFailed = ref(true); // 按钮是否禁用
    const businessId = route.query.businessId; // 商机Id
    const businessType = route.query.type; // 商机类型 1考籍商机 2 app商机
    // 改变订单类型
    const currentOrderType = computed(() => {
      return store.state.client.currentDiscountsType;
    });
    // 监听订单类型改变改变按钮内容
    watch(currentOrderType, (val) => {
      if (val === 2) {
        getCurrentBusinessDetail.currentButtonName = "确认开通";
      } else {
        getCurrentBusinessDetail.currentButtonName = "生成订单并提交优惠审批";
      }
    });
    // 获取客户详细信息
    const getCustomerDetail = () => {
      const customerId = localStorage.getItem("customerId");
      getCustomerDetails({
        customerId: customerId,
      }).then((res) => {
        if (res && res.body) {
          state.baseInfo.name = res.body.name;
          state.baseInfo.mobile = res.body.mobile;
          state.baseInfo.idCard = res.body.idCard;
          state.baseInfo.degreeName = res.body.degreeName;
        }
      });
    };
    // 检测客户是否有身份证号，如果有就直接验证身份证，且不能更改
    const findCustomerIdCards = () => {
      const customerId = localStorage.getItem("customerId");
      findCustomerIdCard(customerId).then((res) => {
        if (res && res.body) {
          checkIdCardHas(res.body);
          state.baseInfo.idCard = res.body;
          idCardDisabled.value = true;
        } else {
          idCardDisabled.value = false;
          getCustomerDetail();
        }
      });
    };
    findCustomerIdCards();
    // 验证是否有这个客户
    const checkIdCardHas = (val) => {
      checkStudentExist({
        idCard: val,
      }).then((res) => {
        if (res && res.body) {
          state.baseInfo.name = res.body.name;
          state.baseInfo.mobile = res.body.mobile;
          state.baseInfo.nation = res.body.nation;
          state.baseInfo.degreeName = res.body.degree === 1 ? "本科" : "专科";
          state.baseInfo.degree = res.body.degree;
          state.baseInfo.sex = res.body.gender;
        }
      }); 
    };
    // 获取商机详情
    const getBusinessDetails = () => {
      getCustomerBusinessById(businessId).then((res) => {
        if (res && res.body) {
          getCurrentBusinessDetail.dataList = res.body;
          flag.value = true;
        }
      });
    };
    // 获取学历选择
    const getDegree = (item) => {
      state.baseInfo.degreeName = item.name;
      state.baseInfo.degree = item.type;
      showModel.isShowDegree = false;
    };
    // 信息必填项是否已填
    const hasDisabled = (type) => {
      hasExamination.value = type;
    };
    // 获取性别
    const getStatusSex = (item) => {
      state.baseInfo.sex = item.type;
    };
    // 获取民族
    const getNation = (item) => {
      state.baseInfo.nation = item.name;
      showModel.isShowNation = false;
    };
    // 提交
    const submitRight = async () => {
      loading.value = true;
      let params = {};
      // 修改学生信息
      await addUserStudent({
        name: state.baseInfo.name,
        idCard: state.baseInfo.idCard,
        mobile: state.baseInfo.mobile,
        nation: state.baseInfo.nation,
        gender: state.baseInfo.sex,
        degree: state.baseInfo.degree,
      }).then((res) => {
        if (res && res.body) {
          console.log("学生信息修改完成!");
        }
      });
      if (businessType == 1) {
        localStorage.setItem("idCard", state.baseInfo.idCard); // 开通App需要
        // 考籍订单
        const examinationParams = examination.value.returnParams();
        params = {
          batchNo: examinationParams.batchNo,
          businessId: businessId,
          examinationId: getCurrentBusinessDetail.dataList.examinationId,
          headTeacher: examinationParams.owner,
          idCard: state.baseInfo.idCard,
          signTime: examinationParams.reportTimes.getTime(),
          shortMessage: examinationParams.isSendMessage,
          waitChangeType: examinationParams.waitChangeType == "是" ? 1 : 2,
          applyType: examinationParams.applyType == "应用型" ? 0 : 1,
          changeBatchNo: examinationParams.changeBatchNo,
        };
        addUserExamination(params).then((res) => {
          if (res && res.body) {
            loading.value = false;
            router.push({
              path: "/paySuccess",
              query: {
                id: res.body,
                type: 1,
                title: `${route.query.reportMessage}`,
              },
            });
          } else {
            Toast({
              message: "该考籍已存在,请勿重复开通",
            });
            loading.value = false;
          }
        });
      } else if (businessType == 2) {
        //app订单 单门课程
        const appParams = appOrder.value.currrentSingular();
        appParams.businessId = businessId;
        appParams.isBusinessAppOrder = 1;
        appParams.order = true;
        businessOrderGoods(appParams).then((res) => {
          if (res && res.body) {
            const sn = res.body.sn;
            const money = res.body.money;
            if (currentOrderType.value === 2) {
              // 不是优惠订单
              checkSnPays(
                () => {
                  if (money) {
                    // 如果支付金额不为零就去支付
                    getPayList.totalPrice = money;
                    getPayList.title = `${getCurrentBusinessDetail.dataList.signTypeName} - ${getCurrentBusinessDetail.dataList.description}`;
                    appParams.goodsList.forEach((item) => {
                      item.name = item.goodsName;
                    });
                    getPayList.checkSuccessList = appParams.goodsList;
                    getPayList.sn = sn;
                    getPayList.currentType =
                      getCurrentBusinessDetail.dataList.signTypeValue === 1 ? 1 : 2;
                    loading.value = false;
                    getPayList.reportMessage = route.query.reportMessage;
                    getPayList.orderDiscount = 2
                    isOrderSuccess.value = true;
                  } else {
                    // 如果为零转为开通成功!
                    const paramsData = {
                      sn: sn,
                    };
                    freeSn(paramsData).then((res) => {
                      if (res && res.body) {
                        router.push({
                          path: "/paySuccess",
                          query: {
                            type: 2,
                            title: "免费开通",
                          },
                        });
                      } else {
                        loading.value = false;
                      }
                    });
                  }
                },
                sn,
                () => {
                  loading.value = false;
                }
              );
            } else {
              // 是优惠订单
              router.push({
                path: "/paySuccess",
                query: {
                  type: 4,
                  aduitPerson: store.state.client.currentAuditMessage.name,
                  title: `${getCurrentBusinessDetail.dataList.signTypeName} - ${getCurrentBusinessDetail.dataList.description}`,
                },
              });
            }
          }
        });
      }
    };
    getBusinessDetails();
    // 监听学生信息是否必填已填
    watch(
      state,
      (val) => {
        if (
          val.baseInfo.name &&
          val.baseInfo.idCard &&
          val.baseInfo.mobile &&
          val.baseInfo.degreeName &&
          val.baseInfo.sex &&
          val.baseInfo.nation &&
          hasExamination.value
        ) {
          isFailed.value = false;
        } else {
          isFailed.value = true;
        }
      },
      { deep: true }
    );
    watch(
      hasExamination,
      (val) => {
        if (val) {
          // 如果必填为真,检测学信息是否都填
          if (
            state.baseInfo.name &&
            state.baseInfo.idCard &&
            state.baseInfo.mobile &&
            state.baseInfo.degreeName &&
            state.baseInfo.nation &&
            state.baseInfo.sex
          ) {
            isFailed.value = false;
          } else {
            isFailed.value = true;
          }
        } else {
          isFailed.value = true;
        }
      },
      { deep: true }
    );
    const checkIdCard = (val) => {
      let flag = reg_idCard.test(val);
      if (flag) {
        checkIdCardHas(val);
      }
      return flag;
    };
    const closePay = () => {
      router.push({
        path: "/client",
      });
    };
    return {
      getNation,
      nationLists,
      educationList,
      reg_idCard,
      reg_phone,
      businessType,
      loading,
      examination,
      hasDisabled,
      flag,
      appOrder,
      idCardDisabled,
      isOrderSuccess,
      getStatusSex,
      getPayList,
      checkIdCard,
      ...toRefs(getCurrentBusinessDetail),
      ...toRefs(state),
      ...toRefs(showModel),
      getDegree,
      closePay,
      sexLists,
      isFailed,
      submitRight,
    };
  },
};
</script>
<style lang="less" scoped>
.conversion-order-wrapper {
  background: #f5f5f5;
  min-height: 100%;
  padding: 40px 32px 160px 32px;
  .student-message {
    padding: 32px 32px 0px 32px;
    box-sizing: border-box;
    border-radius: 24px;
    background: #fff;
    .step-wrapper {
      width: 200px;
    }
  }
  .examination_wrapper {
    margin-top: 48px;
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
  }
}
</style>
