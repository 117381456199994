<template>
  <div class="examination-wrapper">
    <my-nav title=" 完善考籍信息"></my-nav>
    <div class="business-baseInfo-wrapper mt25">
      <div class="bussiness-baseInfo-content">
        <p class="baseInfo-title">报考信息</p>
        <div class="baseInfo-item">
          <div class="baseInfo-item-type">{{ reportMessage }}</div>
        </div>
      </div>
      <div class="bussiness-other-list">
        <van-form @failed="onFailed">
          <van-field
            v-model="state.batchNo"
            label="报名批次"
            label-width="80"
            right-icon="arrow"
            input-align="right"
            @click="isShowReport = true"
            readonly
            clickable
            required
            placeholder="请选择"
          />
          <van-field
            v-model="state.reportTime"
            label="报名时间"
            label-width="80"
            input-align="right"
            right-icon="arrow"
            @click="isShowReportTime = true"
            clickable
            required
            readonly
            placeholder="请选择"
          />
          <van-field
            v-model="state.teacherName"
            label="班主任"
            label-width="80"
            input-align="right"
            required
            right-icon="arrow"
            @click="isShowPrincipalList = true"
            readonly
            clickable
            placeholder="请选择"
          />
          <van-field
            v-if="currentBusinessDetails.signTypeValue === 2"
            v-model="state.applyType"
            label="报考类型"
            label-width="80"
            input-align="right"
            required
            right-icon="arrow"
            @click="isShowReportType = true"
            readonly
            clickable
            placeholder="请选择"
          />
          <van-field
            v-if="
              state.applyType === '社会型' && currentBusinessDetails.signTypeValue === 2
            "
            v-model="state.waitChangeType"
            label="是否待转应用型"
            label-width="80"
            right-icon="arrow"
            input-align="right"
            @click="isShowWatingType = true"
            readonly
            clickable
            required
            placeholder="请选择"
          />
          <van-field
            v-if="
              state.applyType === '社会型' &&
              currentBusinessDetails.signTypeValue === 2 &&
              state.waitChangeType == '是'
            "
            v-model="state.changeBatchNo"
            label="待转批次"
            label-width="80"
            right-icon="arrow"
            input-align="right"
            @click="isShowWatingBatch = true"
            readonly
            clickable
            :required="state.waitChangeType == '是'"
            placeholder="请选择"
          />
          <van-field
            label="是否发送短信"
            label-width="90"
            :border="false"
            input-align="right"
            readonly
            clickable
          >
            <template #input>
              <div>
                <my-status-list
                  :statusList="statusLists"
                  @getStatusList="getStatusList"
                  :checkedData="state.isSendMessage"
                ></my-status-list>
              </div>
            </template>
          </van-field>
          <!-- <div v-if="isShowSendMessage" class="project-lists">
            <textarea
              v-model="state.sendMessage"
              name
              id
              class="text-content"
              placeholder="请输入短信内容..."
            ></textarea>
          </div>-->
        </van-form>
      </div>
    </div>
    <my-nav title=" 价格" class="price"></my-nav>
    <div v-if="Object.keys(obj).length" class="pay-wrapper">
      <div class="pay-item">
        <span class="pay-nav">第一年考籍费</span>
        <span class="pay-number">¥{{ obj.fpay }}</span>
      </div>
      <div class="pay-item">
        <span class="pay-nav">第二年考籍费</span>
        <span class="pay-number">¥{{ obj.spay }}</span>
      </div>
      <div class="pay-item total">
        <span class="pay-nav">考籍费用合计</span>
        <span class="pay-number">¥{{ obj.totalPay }}</span>
      </div>
    </div>
    <div v-else>暂无价格</div>
    <div class="other-actions-sheet" v-if="isShowReport">
      <van-action-sheet
        :show="isShowReport"
        title="报名批次"
        :actions="BatchLists"
        @select="getBatch"
        @cancel="isShowReport = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isShowWatingBatch">
      <van-action-sheet
        :show="isShowWatingBatch"
        title="待转批次"
        :actions="BatchLists"
        @select="getWatingBatch"
        @cancel="isShowWatingBatch = false"
      />
    </div>
    <div class="other-actions-sheet" v-if="isShowWatingType">
      <van-action-sheet
        :show="isShowWatingType"
        title="待转应用型"
        :actions="isWatingUseType"
        @select="getWatingUseType"
        @cancel="isShowWatingType = false"
      />
    </div>
    <van-popup v-model:show="isShowReportTime" position="bottom">
      <van-datetime-picker
        type="datetime"
        v-model="currentTime"
        @confirm="onConfirm"
        @cancel="isShowReportTime = false"
      />
    </van-popup>
    <van-popup
      v-model:show="isShowPrincipalList"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="tree-wrapper">
        <my-tree
          v-if="isShowPrincipalList"
          :departmentLists="treeList"
          :currentId="state.owner"
        ></my-tree>
      </div>
    </van-popup>
    <div class="other-actions-sheet" v-if="isShowReportType">
      <van-action-sheet
        :show="isShowReportType"
        title="报考类型"
        :actions="registerTypeList"
        @select="getGisterType"
        @cancel="isShowReportType = false"
      />
    </div>
  </div>
</template>
<script>
import myNav from "@/components/nav";
import { Field, Cell, Form, ActionSheet, DatetimePicker, Popup } from "vant";
import { reactive, ref, toRefs, computed, watch } from "vue";
import { getAllBatchNo } from "@/api/common";
import myTree from "@/components/myTree";
import myStatusList from "@/components/statusList";
import { getDepartmentList } from "@/api/common";
// import { useRoute } from "vue-router";
import { formatTimeStamp } from "@/utils/commUtil";
import { useStore } from "vuex";
import { getExaminationPay } from "@/api";
import { useRoute } from "vue-router";
import { registerTypeList, isWatingUseType } from "@/utils/const";
export default {
  components: {
    myNav,
    myStatusList,
    myTree,
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  props: {
    currentBusinessDetails: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const route = useRoute();
    const reportMessage = route.query.reportMessage;
    const store = useStore();
    const BatchLists = ref([]);
    const treeList = ref([]);
    const state = reactive({
      isSendMessage: 1,
    });
    const currentTime = ref(new Date());
    const examationId = ref(props.currentBusinessDetails.examinationId);
    const modelShow = reactive({
      isShowReport: false,
      isShowReportTime: false,
      isShowPrincipalList: false,
      isShowSendMessage: true,
      isShowReportType: false,
      isShowWatingBatch: false,
      isShowWatingType: false,
    });
    const payMoney = reactive({
      obj: {},
    }); // 需要支付的金额
    const statusLists = ref([
      {
        type: 1,
        value: "是",
      },
      {
        type: 2,
        value: "否",
      },
    ]);
    // 当前班主任
    const currentPersonal = computed(() => {
      return store.state.client.currentPersonal;
    });
    watch(currentPersonal, (val) => {
      modelShow.isShowPrincipalList = false;
      state.teacherName = `${val.name}【${val.roleName}】`;
      state.owner = val.userRoleId;
    });
    // 获取自考时选择的类型
    const getGisterType = (item) => {
      modelShow.isShowReportType = false;
      state.applyType = item.name;
      if (state.applyType == "应用型") {
        if (state.waitChangeType || state.changeBatchNo) {
          state.waitChangeType = "";
          state.changeBatchNo = "";
        }
      }
    };
    // 获取是否待转应用型
    const getWatingUseType = (item) => {
      modelShow.isShowWatingType = false;
      state.waitChangeType = item.name;
    };
    // 获取负责人列表
    const getDepartmentLists = () => {
      getDepartmentList().then((res) => {
        if (res && res.body) {
          treeList.value = res.body;
        }
      });
    };
    // 获取当前商机应付的款项
    const getExaminationPays = (id) => {
      getExaminationPay(id).then((res) => {
        if (res && res.body) {
          payMoney.obj = res.body;
        }
      });
    };
    if (examationId.value) {
      getExaminationPays(examationId.value);
    }
    // 获取所有报名批次
    const getBatchNoList = () => {
      getAllBatchNo().then((res) => {
        if (res && res.body) {
          BatchLists.value = res.body.map((item, index) => {
            return {
              type: index + 1,
              name: item,
            };
          });
        }
      });
    };
    getDepartmentLists();
    getBatchNoList();
    // 获取当前报名批次
    const getBatch = (item) => {
      modelShow.isShowReport = false;
      state.batchNo = item.name;
    };
    const getWatingBatch = (item) => {
      //获取当前待转批次
      modelShow.isShowWatingBatch = false;
      state.changeBatchNo = item.name;
    };
    // 获取报名时间
    const onConfirm = (item) => {
      currentTime.value = item;
      state.reportTime = formatTimeStamp(item);
      state.reportTimes = item;
      modelShow.isShowReportTime = false;
    };
    // 获取是否发送短信
    const getStatusList = (item) => {
      state.isSendMessage = item.type;
      if (item.type == 1) {
        modelShow.isShowSendMessage = true;
      } else {
        modelShow.isShowSendMessage = false;
        state.sendMessage = "";
      }
    };
    // 监听必填项
    watch(
      state,
      (val) => {
        // 报名批次和报名时间必选
        if (val.batchNo && val.reportTimes && val.teacherName) {
          if (props.currentBusinessDetails.signTypeValue === 2) {
            if (state.applyType == "社会型") {
              if (state.waitChangeType == "是") {
                if (state.changeBatchNo) {
                  context.emit("hasDisabled", true);
                } else {
                  context.emit("hasDisabled", false);
                }
              } else if(state.waitChangeType == "否") {
                context.emit("hasDisabled", true);
              } else {
                context.emit('hasDisabled', false)
              }
            } else if (state.applyType == "应用型") {
              context.emit("hasDisabled", true);
            } else {
              context.emit("hasDisabled", false);
            }
          } else {
            context.emit('hasDisabled', true)
          }
        } else {
          context.emit("hasDisabled", false);
        }
      },
      { deep: true }
    );
    // 返回当前参数
    const returnParams = () => {
      if (props.currentBusinessDetails.signTypeValue !== 2) {
        // 不为自考本科时
        state.applyType = "社会型";
      }
      return state;
    };
    return {
      state,
      getBatch,
      onConfirm,
      treeList,
      BatchLists,
      isWatingUseType,
      currentTime,
      statusLists,
      getStatusList,
      reportMessage,
      registerTypeList,
      getWatingUseType,
      returnParams,
      getGisterType,
      getWatingBatch,
      ...toRefs(payMoney),
      ...toRefs(modelShow),
    };
  },
};
</script>
<style lang="less" scoped>
.examination-wrapper {
  .business-baseInfo-wrapper {
    padding: 24px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 24px;
    .bussiness-baseInfo-content {
      padding: 24px;
      box-sizing: border-box;
      background: #fafafa;
      border-radius: 12px;
      .baseInfo-title {
        font-size: 30px;
        color: #666666;
        font-weight: bold;
      }
      .baseInfo-item {
        padding: 24px 0px;
        display: flex;
        .baseInfo-item-type {
          min-width: 128px;
          font-size: 26px;
          color: #999999;
        }
      }
    }
    .bussiness-other-list {
      .pirce-object {
        padding: 32px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 24px;
        font-size: 28px;
        color: #333;
        .price-total {
          margin-top: 65px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .project-lists {
        .text-content {
          width: 100%;
          height: 92px;
          resize: none;
          background: #fafafa;
          border: none;
        }
      }
    }
  }
  .price {
    margin-top: 48px;
  }
  .pay-wrapper {
    margin-top: 15px;
    background: #fff;
    border-radius: 24px;
    padding: 0 32px;
    box-sizing: border-box;
    .pay-item {
      display: flex;
      justify-content: space-between;
      padding: 32px 0px;
      border-bottom: 1px solid #eee;
      color: #333333;
      font-size: 28px;
      &:last-child {
        border: none;
      }
    }
    .total {
      font-weight: bold;
    }
  }
}
</style>
